<template>
  <section>
    <v-carousel
      :continuous="false"
      :show-arrows="false"
      hide-delimiter-background
      delimiter-icon="mdi-minus"
      height="600"
    >
      <v-carousel-item
        v-for="(item,i) in items"
        :key="i"
        :src="item.src"
      >
        <v-parallax style="height:100%">
          <v-layout
            column
            align-center
            justify-center
            class="white--text"
          >
            <h1 class="white--text mb-2 display-1 text-center">
              {{ item.titulo }}
            </h1>
            <div class="subheading mb-4 text-center">
              {{ item.contenido }}
            </div>
            <v-btn
              class="mt-12 success"
              dark
              large
            >
              Ir
            </v-btn>
          </v-layout>
        </v-parallax>
      </v-carousel-item>
    </v-carousel>
  </section>
</template>

<script>

export default {

	data () {
		return {
			/* eslint-disable */
      items: [
        {
          src: require('@/assets/home_slide_1.jpg'),
          titulo: 'Syhcomputacion',
          contenido: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
        },
        {
          src: require('@/assets/home_slide_2.jpg'),
          titulo: 'Control de Restaurante',
          contenido: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
        },
        {
          src: require('@/assets/home_slide_3.jpg'),
          titulo: 'Mensajeria Instantania',
          contenido: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
        }
      ]
      /* eslint-enable */
		}
	},
	created: function () {
	},
	methods: {
	}
}
</script>
