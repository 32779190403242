<template>
  <div>
    <v-toolbar
      color="#ffffff"
      height="80"
    >
      <v-layout>
        <v-img
          :src="require('@/assets/logo_ancho.webp')"
          class="mr-5"
          contain
          height="62"
          width="260"
          max-width="260"
        />
        <v-spacer />
        <v-app-bar-nav-icon
          class="hidden-md-and-up"
          @click.stop="drawer = !drawer"
        />
        <div class="hidden-sm-and-down ml-0 mt-2">
          <v-btn
            text
            class="ml-0 mt-2"
            @click="GoTo('/')"
          >
            INICIO
          </v-btn>
          <!-- <v-btn text  class="ml-0 mt-2" @click="GoTo('/')" > SOBRE NOSOTROS </v-btn> -->
          <!--  <v-btn text  class="ml-0 mt-2" @click="GoTo('/')" > CONTACTO </v-btn> -->
          <v-btn
            v-if="authS != 'success'"
            text
            class="ml-0 mt-2 "
            color="red darken-1"
            dark
            @click="GoTo('/auth')"
          >
            INGRESAR
          </v-btn>
          <v-btn
            v-if="authS == 'success'"
            text
            class="ml-0 mt-2 "
            color="red darken-1"
            dark
            @click="GoTo('/user')"
          >
            ZONA CLIENTES
          </v-btn>
        </div>
      </v-layout>
    </v-toolbar>

    <v-navigation-drawer
      v-model="drawer"
      class="hidden-md-and-up"
      temporary
      :clipped="$vuetify.breakpoint.lgAndDown"
      app
    >
    <!--
      <template #prepend>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-img src="https://image.flaticon.com/icons/svg/149/149071.svg" />
            </v-list-item-avatar>
          </v-list-item>
        </v-list>
      </template>
    -->
      <v-divider />

      <v-list
        nav
        dense
      >
        <v-list-item @click="GoTo('/')">
          <v-list-item-icon >
            <v-icon >mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title >INICIO</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="authS != 'success'"
          @click="GoTo('/auth')"
        >
          <v-list-item-icon>
            <v-icon>mdi-lock</v-icon>
          </v-list-item-icon>
          <v-list-item-title style="color:red">
            INGRESAR
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="authS == 'success'"
          @click="GoTo('/user')"
        >
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title style="color:red">
            ZONA CLIENTES
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>

export default {

	props: {
		GoTo: Function
	},
	data () {
		return {
			drawer: null
		}
	},
	computed: {
		authS () {
			return this.$store.getters.authStatus
		}
	},
	created: function () {
		// console.log('size call ')
	},
	methods: {
	}
}
</script>
