<template>
  <v-app light>
    <ToolBar :go-to="GoTo" />

    <v-content>
      <CarouselCentral />

      <!-- <Nosotros></Nosotros> -->
      <!-- <WeServices></WeServices> -->
      <!-- <ClientZone></ClientZone> -->

      <Contact />

      <v-footer color="#003853">
        <v-layout
          row
          wrap
          align-center
        >
          <v-flex xs12>
            <div
              class="white--text ml-4"
              style="text-align: center;"
            >
              All rights reserved
              by Syhsoftware  <v-icon
                color="#569F3F"
                class="text"
                small
              >
                mdi-wifi
              </v-icon>
            </div>
          </v-flex>
        </v-layout>
      </v-footer>
    </v-content>
  </v-app>
</template>

<script>

import ToolBar from './ToolBar'
import CarouselCentral from './CarouselCetral'
import Contact from './Contact'

export default {

	components: {
		ToolBar,
		CarouselCentral,
		Contact
	},
	data () {
		return {
		}
	},
	computed: {
		user () {
			console.log(this.$store.state.user)
			console.log(this.$store)
			return this.$store.state.user
		}
	},
	created: function () {
	},
	methods: {
		GoTo (url) {
			this.$router.push(url)
		}

	}
}
</script>
